@import "@styles/helpers/variables";

.carouselGallery {
  max-width: $site-width;
  width: 100%;
  margin: 0 auto 0;

  div {
    background-color: transparent;
  }
}

.carouselImage {
  @media only screen and (max-width: $large) {
    margin: 0 0.5rem;
    border-radius: 10%;
    overflow: hidden;
  }

  img {
    object-fit: cover;

    @media only screen and (max-width: $large) {
      object-fit: cover;
    }
  }
}

.carouselText {
  background: none;
  p {
    margin: 0;
  }
  span {
    display: inline-block;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    padding-left: 1rem;
    width: 4rem;
    height: 30px;
  }
}

.gallery_wrapper {
  max-width: 1250px;
  margin: 2rem auto;
  padding: 0;
  display: block;
}

.gallery4K {
  display: none;

  @media screen and (min-width: 2048px) {
    display: block;
  }
}

.galleryJumboDesktop {
  display: none;

  @media screen and (min-width: 1660px) and (max-width: 2047px) {
    display: block;
  }
}

.galleryLargeDesktop {
  display: none;

  @media screen and (min-width: 1440px) and (max-width: 1659px) {
    display: block;
  }
}

.galleryDesktop {
  display: none;

  @media screen and (min-width: 1023px) and (max-width: 1439px) {
    display: block;
  }
}

.galleryTablet {
  display: none;

  @media screen and (max-width: 1023px) and (min-width: $small) {
    display: block;
  }
}

.galleryMobile {
  display: none;

  @media screen and (max-width: $small) {
    display: block;
    max-width: 90%;
  }
}

.infoContainer {
  margin-top: 2%;
  display: flex;
  gap: 1%;
  flex-direction: row;
  place-content: center;
  place-items: center;

  .instagramHandle {
    text-align: center;
    color: #25273c;
    font-weight: 550;

    margin: 0;
    padding: 0;
  }

  .instagramIcon {
    margin: 1% 0 0;
  }
}

.slick-arrow {
  display: none !important;
}
